@font-face {
    font-family: "Carta Magna";
    src: url(Carta_Magna-line-demo-FFP.ttf);
}

@font-face {
    font-family: "Pfeffer Mediaeval";
    src: url(PfefferMediaeval.otf);
}

@font-face {
    font-family: "King Castle";
    src: url(King\ Castle\ Free\ Version.otf);
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background-color: #181818; */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
